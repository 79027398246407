<template>
  <div class="reward">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="reward-content">
      <!-- <topContent @handleSearchClick="handleSearchClick" @queryInfoChange="handleQueryInfoChange"></topContent> -->
      <div class="top-content">
        <div>
          <el-date-picker
              :clearable="false"
              v-model="queryInfo.date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              type="date" />
          <el-select
              v-model="queryInfo.shopId"
              class="m-2"
              @change="handleShop"
              placeholder="选择门店">
              <el-option v-for="item in shopList"
                  :key="item.shopId"
                  :label="item.shopName"
                  :value="item.shopId">
              </el-option>
          </el-select>
          <el-button @click="handleSearchClick" type="primary">搜索</el-button>
          <el-button @click="handleRefreshClick" type="primary">刷新<el-icon><Refresh /></el-icon></el-button>
        </div>
        <div class="left_statistics">
          <p>目标客户：{{statistics.data.targetCustomerCount||0}}</p>
          <p>待计划：{{statistics.data.waitingPlanCount||0}}</p>
          <p>待复盘：{{statistics.data.waitingForReview||0}}</p>
        </div>
    </div>
      <el-table :data="dataList" style="width: 100%">
        <el-table-column label="基础信息" align="center">
          <el-table-column prop="date" label="预约日期" width="150px" />
          <el-table-column prop="userName" label="客户姓名" width="150px">
            <template #default="scope">
              <el-link type="primary" @click="goUserDetail(scope.row)">{{ scope.row.userName }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width="150px" />
          <el-table-column prop="empName" label="服务人员" width="150px" />
          <el-table-column prop="userAttribute" label="客户属性" width="150px">
            <template #default="scope">
              <p v-if="scope.row.userAttribute==1">新客</p>
              <p v-if="scope.row.userAttribute==2">续卡</p>
            </template>
          </el-table-column>
          <el-table-column prop="userCardBalance" label="账户情况" width="160px">
            <template #default="scope">
              <p>储值卡余额：¥{{ scope.row.userCardBalance||0 }}</p>
              <p>次卡余额：{{ scope.row.userServeBalance||0 }}次</p>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="销售计划" align="center">
          <el-table-column prop="exploringNeeds" label="探寻需求" width="150px">
            <template #default="scope">
              <div>{{ scope.row.exploringNeeds }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="matchingScheme" label="匹配方案" width="150px">
            <template #default="scope">
              <div>{{ scope.row.matchingScheme }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="销售复盘" align="center" width="150px">
          <el-table-column prop="provideSolutions" label="提供方案" width="150px" />
          <el-table-column prop="successfullyReceivedPayment" label="收款成功" width="150px" />
          <el-table-column prop="replay" label="复盘" width="150px" />
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center" fixed="right" width="150px">
          <template #default="scope">
            <el-button link type="text" size="small" @click="handleSetClick(scope.row.id,scope.row)" v-has="'sale:log:edit'">设置销售计划</el-button>
            <el-button v-if="scope.row.status==2||scope.row.status==3" link type="text" size="small" @click="handleReplayClick(scope.row.id,scope.row)" v-has="'sale:log:edit'">复盘</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      style="padding-top: 20px;"
      :current-page="queryInfo.current" :page-size="queryInfo.size" :page-sizes="[10, 20, 30]"
      layout="total, sizes, prev, pager, next, jumper" :total="dataCount"></el-pagination>
    </div>
    <salesPlan :logId="logId" :logItem="logItem" :dialogVisible="distributeDialogVisible" @changeDialogVisible="changeDistributeDialogVisible" @refresh="refresh"></salesPlan>
    <replay :logId="logId" :logItem="logItem" :dialogVisible="distributeReplayDialogVisible" @changeDialogVisible="changeReplayDistributeDialogVisible" @refresh="refresh"></replay>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import { getTargetCustomersList,getTargetCustomersStatistics,refreshTargetCustomer } from '@/service/main/reserve'
import { COMPANY_ID } from '@/common/constant'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
import salesPlan from './cpns/sales-plan/index.vue'
import replay from './cpns/replay-form/index.vue'
import { useRoute,useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { getShopList } from '@/service/main/order'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    Breadcrumb,
    topContent,
    salesPlan,
    replay
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const ruleForm = reactive({
      start: 1,
      pagesize: 10,
      empId: 0
    })

    const shopList = ref([])
    const handleSizeChange = pagesize => {
     queryInfo.size = pagesize
      getDataList()
    }

    const handleCurrentChange = page => {
      queryInfo.current = page
      getDataList()
    }

    const queryInfo = reactive({
      current: 1,
      size: 10,
      shopId: 0,
      date: route.query.date?route.query.date:dayjs(new Date()).format('YYYY-MM-DD')
    })

    const handleShop = (e) => {
        queryInfo.shopId = e
    }

    const goUserDetail = (item) =>{
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: item.userId,
          phone: item.phone
        }
      })
    }

    const handleSearchClick=()=>{
        getDataList()
    }
    const handleRefreshClick = async() =>{
      let res = await refreshTargetCustomer({shopId:queryInfo.shopId,date:queryInfo.date})
      if (res.code==0) {
        ElMessage.success({
          message: '刷新成功!',
          type: 'success'
        })
        getDataList()
      }else{
        ElMessage.error({
          message: res.msg,
          type: 'error'
        })
      }
    }
    const logId = ref(null)
    const logItem = ref({})
    const distributeDialogVisible = ref(false)
    const distributeReplayDialogVisible = ref(false)
    const changeDistributeDialogVisible = flag => {
      distributeDialogVisible.value = flag
      if (!flag) {
        logId.value = null
        logItem.value = {}
      }
    }
    const changeReplayDistributeDialogVisible = flag => {
      distributeReplayDialogVisible.value = flag
      if (!flag) {
        logId.value = null
        logItem.value = {}
      }
    }

    const handleSetClick = (id,item) => {
      logId.value = id
      logItem.value = item
      distributeDialogVisible.value = true
    }
    const handleReplayClick = (id,item) => {
      logId.value = id
      logItem.value = item
      distributeReplayDialogVisible.value = true
    }
    const refresh = () => {
      getDataList()
    }

    const getDataList = async() => {
      const res = await getTargetCustomersList({ ...queryInfo })
      const resp = await getTargetCustomersStatistics({shopId:queryInfo.shopId,date:queryInfo.date})
      statistics.data = resp.data?resp.data:{
          targetCustomerCount:0,
          waitingForReview:0,
          waitingPlanCount:0
        }
      dataList.value = res.data?res.data.list:[]
      dataCount.value = Number(res.data?res.data.total:0)
    }

    const dataList = ref([])
    const statistics = reactive(
      {
        data:{
          targetCustomerCount:0,
          waitingForReview:0,
          waitingPlanCount:0
        }
      })
    const dataCount = ref(0)
    const initPage = async () => {
      const shopRes = await getShopList(COMPANY_ID)
      shopList.value = shopRes.data.list
      queryInfo.shopId = route.query.shopId?Number(route.query.shopId):shopRes.data.list[0].shopId
      getDataList()
    }
    initPage()


    return {
      handleSearchClick,
      handleRefreshClick,
      handleShop,
      queryInfo,
      ruleForm,
      dataList,
      dataCount,
      contentTableConfig,
      handleSizeChange,
      handleCurrentChange,
      breadcrumbList,
      changeDistributeDialogVisible,
      changeReplayDistributeDialogVisible,
      distributeDialogVisible,
      distributeReplayDialogVisible,
      logId,
      logItem,
      handleSetClick,
      handleReplayClick,
      refresh,
      getDataList,
      shopList,
      statistics,
      goUserDetail
    }

  }
})
</script>

<style scoped lang="less">
.reward {
  .reward-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    .top-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      .left_statistics{
        display: flex;
        justify-content: space-between;
        width: 300px;
        // justify-content: flex-end;
      }

      &:deep(.el-select) {
        margin-left: 10px;
      }

      &:deep(.el-button) {
        margin-left: 10px;
      }
    }
  }
  &:deep(.el-table) {
  // font-size: 12px;
   thead.is-group th.el-table__cell{
      background-color: rgba(239, 239, 239, 1) !important;
      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
  .el-checkbox {
    // height: auto !important;
  }


  .el-table__header-wrapper {
    
    .el-table__cell {

      background-color: rgba(239, 239, 239, 1);
      // padding: 9px 0 !important;
      border:1px solid rgb(223, 223, 223);

      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

  }
}
}
.ellipsis-text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
</style>
