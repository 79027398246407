export const contentTableConfig = {
  propList: [
    { prop: 'orderNo', label: '订单号', slotName: 'orderNo' },
    { prop: 'createTime', label: '打赏时间' },
    {
      prop: 'shopName',
      label: '门店名称',
      slotName: 'shopName'
    },
    {
      prop: 'userRealname',
      label: '被打赏人',
      slotName: 'userRealname'
    },
    {
      prop: 'userNick',
      label: '用户信息',
      slotName: 'userNick'
    },
    { prop: 'price', label: '打赏金额', slotName: 'price' },
    // { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}

export const breadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '销售日志',
    path: ''
  }
]
