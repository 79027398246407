export const replayPlan = {
  provideSolutions: [
    { required: true, message: '请输入提供方案', trigger: 'blur' }
  ],
  successfullyReceivedPayment: [
    { required: true, message: '请输入收款情况', trigger: 'blur' }
  ],
  replay: [
    { required: true, message: '请输入复盘内容', trigger: 'blur' }
  ]
}
