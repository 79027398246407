<template>
  <div class="dialog-form">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="设置销售计划"
    >
      <div style="padding: 10px 0;">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="setPlan"
          label-width="100px"
          class="demo-ruleForm"
          :size="formSize"
          label-suffix="："
        >
          <el-form-item label="探寻需求" prop="exploringNeeds">
            <el-input placeholder="请输入探寻需求" v-model="ruleForm.exploringNeeds" :rows="3"
    type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="匹配方案" prop="matchingScheme">
            <el-input placeholder="请输入匹配方案" v-model="ruleForm.matchingScheme" :rows="3"
    type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="save-btn">
        <el-button type="primary" @click="handleSaveClick(ruleFormRef)">保存</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { setPlan } from './config'
import { ElMessage } from 'element-plus'
import { setAPlan } from '@/service/main/reserve'
import { useRoute } from 'vue-router'
import router from '@/router'
import emitter from '@/utils/eventbus'
import PageDialog from '@/components/page-dialog/page-dialog.vue'

export default defineComponent({
  props: {
    logId: {
      type: Number,
      default: null
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    logItem: {
      type: Array,
      default: {}
    }

  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible','refresh'],
  setup(props, { emit }) {

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const route = useRoute()
    const store = useStore()

    const ruleFormRef = ref()
    const ruleForm = reactive({
      matchingScheme: '',
      exploringNeeds: ''
    })
    watch(() => props.logItem, (newValue) => {
      if (newValue) {
        ruleForm.matchingScheme=newValue.matchingScheme
        ruleForm.exploringNeeds=newValue.exploringNeeds
      }else{
        ruleForm.matchingScheme=''
        ruleForm.exploringNeeds=''
      }
    }, {
      deep: true
    })


    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await setAPlan({...ruleForm,targetCustomerId:props.logId})
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            ruleForm.matchingScheme=''
            ruleForm.exploringNeeds=''
            changeDialogVisible(false)
            emit('refresh')
          }else {
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          }
        } else {
          ElMessage({
            message: '带*为必填项,请正确填写数据',
            type: 'warning',
          })
        }
      })
    }

    return {
      ruleForm,
      handleSaveClick,
      setPlan,
      changeDialogVisible,
      ruleFormRef
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {

  .save-btn {
    text-align: right;
    max-width: 800px;
  }
}
</style>
