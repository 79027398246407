<template>
    <div class="top-content">
        <el-date-picker
            v-model="queryInfo.date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            type="date" />
        <el-select clearable
            @change="handleShopChange"
            v-model="queryInfo.shopId"
            class="m-2"
            placeholder="选择门店">
            <el-option v-for="item in shopList"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId">
            </el-option>
        </el-select>
        <el-button @click="handleSearchClick"
            type="primary">搜索</el-button>
    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { getShopList } from '@/service/main/order'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'

export default defineComponent({
  props: {

  },
  components: {

  },
  emits: ['queryInfoChange', 'handleSearchClick'],
  setup(props, { emit }) {

    const shopList = ref([])

    const handleSearchClick = () => {
      emit('handleSearchClick')
    }

    const queryInfo = reactive({
      shopId: '',
      date: dayjs(new Date()).format('YYYY-MM-DD')
    })

    watch(queryInfo, (n) => {
      emit('queryInfoChange', { ...n })
    }, {
      deep: true
    })

    const initPage = async () => {
      const shopRes = await getShopList(COMPANY_ID)
      shopList.value = shopRes.data.list
    }
    initPage()

    return {
      handleSearchClick,
      shopList,
      queryInfo,

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  &:deep(.el-select) {
    margin-left: 10px;
  }

  &:deep(.el-button) {
    margin-left: 10px;
  }
}
</style>




